export default function sibForm() {
    const forms = document.querySelectorAll('.js-sib-form');

    if (!forms) return;

    forms.forEach((form) => {
        const errorMessage = form.querySelector('.js-sib-form-error-message');

        form.addEventListener('submit', (event) => {
            /* global dataLayer */
            window.dataLayer = window.dataLayer || [];

            event.preventDefault();

            if (form.checkValidity()) {
                dataLayer.push({
                    event: 'generate_Lead',
                    formName: form.dataset.formName,
                });
            }

            const formData = new FormData(form);

            fetch(form.action, {
                method: 'POST',
                body: formData,
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.success && response.redirect) {
                        errorMessage.classList.add('hidden');

                        window.location.href = response.redirect;
                    } else {
                        errorMessage.classList.remove('hidden');
                    }
                });
        });
    });
}

window.addEventListener('load', sibForm);
